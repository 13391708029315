<template>
    <div class="web-app" :class="{ 'not-logged-user' : !isLoggedIn }">
        <transition name="slide-top" mode="out-in">
            <onboarding-process v-if="showOnboarding" />
        </transition>
        <intro-modal @show-intro="showIntro = true" />
        <create-board-modal />
        <search-bubble
            v-if="shouldShowSearch && !showTabBar"
        />
        <nav-bar-pill
            v-if="isLoggedIn && !showTabBar"
            v-observe-visibility="(isVisible) => isNavBarVisible = isVisible"
            :show-intro="showIntro"
            :navigation-links="navigationLinks"
            @move-to-route="moveToRoute"
            @on-intro-completed="setUserWelcome"
            @on-exit="setUserWelcome"
            @is-loading="(value) => $emit('is-loading', value)"
            @reload-composer="$refs.WebApp.reloadComposer()"
        />
        <nav-bar-auth v-if="!isLoggedIn" />
        <tab-bar
            v-if="showTabBar"
            :show-intro="showIntro"
            :navigation-links="navigationLinks"
            @move-to-route="moveToRoute"
            @on-intro-completed="setUserWelcome"
            @on-exit="setUserWelcome"
        />
        <transition name="fade" mode="out-in">
            <router-view ref="WebApp" @is-loading="(value) => $emit('is-loading', value)" />
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    name: "WebApp",
    components: {
        IntroModal: () => import(/* webpackChunkName: "intro-modal" */ "@c/organisms/modals/intro-modal"),
        SearchBubble: () => import(/* webpackChunkName: "search-bubble" */ "@c/organisms/search-bubble"),
        NavBarPill: () => import(/* webpackChunkName: "nav-bar-pill" */ "@c/organisms/nav-bar-pill"),
        NavBarAuth: () => import(/* webpackChunkName: "nav-bar-auth" */ "@c/organisms/nav-bar-auth"),
        TabBar: () => import(/* webpackChunkName: "tab-bar" */ "./tab-bar"),
        CreateBoardModal: () => import(/* webpackChunkName: "create-board-modal" */ "@/components/organisms/modals/create-board.vue"),
        OnboardingProcess: () => import(/* webpackChunkName: "onboarding-process" */ "./onboarding/")
    },
    data() {
        return {
            isNavBarVisible: true,
            showIntro: false,
            navigationLinks: [
                {
                    name: "Home",
                    icon: require("@assets/img/icons/app/home.svg"),
                    routeName: "web-app-home",
                    introText: "<h1>Home Feed</h1><p>This is your feed of Memos we think you’ll love and creators you’re following.</p>",
                    routeParams: {}
                },
                {
                    name: "Create",
                    icon: require("@assets/img/icons/app/create.svg"),
                    routeName: "web-app-create",
                    introText: "<h1>Create Memo</h1><p>Write your own Memo and share about whatever inspires you.</p>",
                    routeParams: {}
                },
                {
                    name: "Inbox",
                    icon: require("@assets/img/icons/app/inbox.svg"),
                    routeName: "web-app-inbox",
                    introText: "<h1>Inbox and Notifications</h1><p>Your inbox will notify you about new Memos from Creators and topics you follow, along with notifications about new followers, comments and more.</p>",
                    routeParams: {}
                },
                {
                    name: "Profile",
                    icon: require("@assets/img/icons/app/profile.svg"),
                    routeName: "web-app-profile",
                    introText: "<h1>My profile</h1><p>Introduce yourself to the community by adding a photo and bio.</p>",
                    routeParams: {
                        displayName: `${this.$store.state.User.data.displayname}`
                    }
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            userData: state => state.User.data,
            isMobileScreen: state => state.Application.isMobileScreen,
            showOnboarding: state => state.Application.showOnboarding
        }),
        showTabBar() {
            return this.isLoggedIn && this.isMobileScreen;
        },
        shouldShowSearch() {
            return !this.$route.name.includes("web-app-search") && !this.$route.name.includes("memod-reader") && this.isLoggedIn;
        }
    },
    updated() {
        const profileNavigationIndex = this.navigationLinks.findIndex(navigation => navigation.routeParams.displayName);
        this.navigationLinks[profileNavigationIndex].routeParams.displayName = `${this.userData.displayname}`;
    },
    methods: {
        moveToRoute(event) {
            this.$router.push({ path: `/${event.href.split("/").pop()}` })
        },
        setUserWelcome() {
            axios.put(`/users/${this.userData.id}`, { welcome: 1 }).then(({ data }) => {
                this.$store.dispatch("User/setData", data);
            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.web-app {
    background: #0F0F10;

    .nav-bar {
        position: relative;
        z-index: 10;
    }

    /deep/ .web-app-sidebar {
        z-index: 10;
        padding-top: 0;
    }

    /deep/ .search-edit {
        padding-top: 25px;
    }

    /deep/ .tab-bar-user-panel {
        z-index: 9 !important;
        position: fixed;
    }

    /deep/ .tab-bar-user-panel + .container {
        padding-top: 165px;
    }

    /deep/ .web-app-search {
        .tab-bar-user-panel {
            border-bottom: 1px solid #4b4b4b;
        }
    }
}
</style>
